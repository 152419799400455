export default {
    setShowPP (state, payload) {
        state.showpp = payload.showpp
    },    
    setLastPage(state, payload){
        state.lastPage=payload.lastPage;
    },
    setSelected(state, payload){
        state.selected=payload.selected;
        localStorage.setItem('selected', payload.selected);
    },
    setViewHeight(state, payload) {
        state.viewHeight = payload.viewHeight;
    }    
}