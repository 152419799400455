<template>
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>
    <mainDiv id="main">
        <div class="title1" role="heading">Scrapbooking / Quilting Retreats</div>
        <div class="row1">
            <img alt="A room full of nicely arranged large tables each with a single office chair." class="img1" src="../../assets/retreats/thumbnails/scrapbooking.jpg" />
            <div class="text2">
                <div>
                    <span class="span1">The Inn At Windmere, LLC is pleased to host scrapbooking and quilting groups in our Sunshine Room: </span>
                    <span class="span2">This delightful, cheery large room will accommodate at least 12 scrapbookers or quilters. It also has a sitting area to comfortably take a snack break or watch a video with your friends. It is well lit, has lots of table space and an atmosphere conducive to a fun, productive gathering.</span>
                </div>
                <div class="textLink" role="button" @click="dispatch('scrapbooking','retreats')">Continue Reading</div>
            </div>
        </div>
        <div class="title1" role="heading">Church Retreats</div>
        <div class="row1">
            <img alt="A large room with a grand piano, two large couches, and a fireplace." class="img1" src="../../assets/2023Update/Church/Great-Room-5.jpg" />
            <div class="text2">
                <div>
                    <span class="span1">The Inn At Windmere, LLC would be thrilled to host church retreats. </span>
                    <span class="span2">Whether a men or women’s conference, a couples’ retreat or a church staff meeting, you will find the Inn conducive to learning, reflection and growth as well as relaxation.</span>
                </div>
                <div class="textLink" role="button" @click="dispatch('church','retreats')">Continue Reading</div>
            </div>
        </div>
    </mainDiv>
</template>

<script>
import router from '../../router.js';
import MainDiv from '../MainDiv.vue';
export default {    
  components: {
    'mainDiv': MainDiv
  },
  methods: {
    dispatch(name){
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'retreats' }}));
        router.replace({path: name});
    },
  },
  mounted() {
    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'retreats' }}));
  }
}
</script>
<style scoped>
.row1 {
    display:flex;
    margin-bottom:30px;
    margin-top:10px;
}
.img1{
    width:50%;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.text2 {    
    padding:0 20px 0 20px;
}
.textLink {
    padding-top:15px;
}
.span1{
    font-weight:300;
}
@media only screen and (max-width: 1152px) {
    .row1 {
        display:block;
    }
    .row1 > img {
        width:100%;
    }
    .text2 {
        padding:0;
        margin-top:15px;
    }
}
</style>