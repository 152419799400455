<template>    
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>
    <mainDiv2 id="main">
        <template v-slot:slot1>
            <div class="title1" role="heading">Lilac Room</div>
            <div class="roomDesc">Allow your senses to be ensconced with all the delights of a lilac garden as you step into this ethereal haven.</div>
            <div class="roomImg">
                <div class="imgDiv1">
                    <div class="leftBtn" tabindex="30" v-on:keyup.enter="imgClick('left')" role="button"  @click="imgClick('left')">&lt;</div>
                    <transition>
                        <img alt="A carousel with 6 photos displaying the Lilac Room" class="imgCenter" :key="imgIndex" :src="getImagePath()" />
                    </transition>
                    <div class="rightBtn" tabindex="31" v-on:keyup.enter="imgClick('right')" role="button"  @click="imgClick('right')">></div>
                </div>            
            </div>
            <ul>
                <div class="featureList" v-for="str in features.split('\n')" :key="str">
                    <li>{{str}}</li>
                </div>
            </ul>
            <div class="text2">{{text2}}</div>
            <div class="textPrice">$160.00/night + 12% tax</div>
            <div class="textPriceSub">(All prices subject to change without notice)</div>
            <div class="space1"></div>
            <div class="title1" role="heading">Amenities</div>
            <div class="space2"></div>
            <div class="block7">
                <div class="block4">
                    <div class="block5">
                        <!-- <div class="liSpace"></div> -->
                        <ul class="list1">
                            <li role="button" tabindex="32"  v-on:keyup.enter="selectAmen('comforts')" @click="selectAmen('comforts')" :class="{'styleSelectedTop': selected==='comforts'}">Comforts</li>
                            <li role="button" tabindex="33"  v-on:keyup.enter="selectAmen('spa')" @click="selectAmen('spa')" :class="{'styleSelected': selected==='spa'}">Spa</li>
                            <li role="button" tabindex="35"  v-on:keyup.enter="selectAmen('pleasures')" @click="selectAmen('pleasures')" :class="{'styleSelected': selected==='pleasures'}">Pleasures</li>
                            <li role="button" tabindex="36"  v-on:keyup.enter="selectAmen('helps')" @click="selectAmen('helps')" :class="{'styleSelected': selected==='helps'}">Helps</li>
                            <li role="button" tabindex="37"  v-on:keyup.enter="selectAmen('outdoorFun')" @click="selectAmen('outdoorFun')" :class="{'styleSelected': selected==='outdoorFun'}">Outdoor Fun</li>
                            <li role="button" tabindex="38"  v-on:keyup.enter="selectAmen('extras')" @click="selectAmen('extras')" :class="{'styleSelected': selected==='extras'}">Extras</li>
                        </ul>
                        <div class="liSpace"></div>
                    </div>
                    <div class="block8" @click="showAmenList=!showAmenList">
                        <div class="amenDropDown">{{selTitle}}<img alt="Dropdown Icon" src="../../../assets/ddIcon.png" /></div>
                    </div>     
                    <div class="block9" v-if="showAmenList">
                        <ul>
                            <li @click="selectAmen('comforts'); showAmenList=false" :class="{'styleSelectedTop': selected==='comforts'}">Comforts</li>
                            <li @click="selectAmen('spa'); showAmenList=false" :class="{'styleSelected': selected==='spa'}">Spa</li>
                            <li @click="selectAmen('pleasures'); showAmenList=false" :class="{'styleSelected': selected==='pleasures'}">Pleasures</li>
                            <li @click="selectAmen('helps'); showAmenList=false" :class="{'styleSelected': selected==='helps'}">Helps</li>
                            <li @click="selectAmen('outdoorFun'); showAmenList=false" :class="{'styleSelected': selected==='outdoorFun'}">Outdoor Fun</li>
                            <li @click="selectAmen('extras'); showAmenList=false" :class="{'styleSelected': selected==='extras'}">Extras</li>
                        </ul>                            
                    </div>
                    <div class="block6">
                        <div class="blockTitle">{{amenTitle}}</div>
                        <ul>
                            <div  class="amenList" v-for="str in amenData.split('\n')" :key="str">
                                <li>{{str}} <span v-if="selected==='spa'" class="textLink" v-on:keyup.enter="dispatch('amenities','amenities')" tabindex="34" @click="dispatch('amenities','amenities', { setScroll: 'bottom'})">Click here for more information</span></li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:slot2>
            <div class="title4" role="heading">Rooms</div>
            <div class="textLink" role="button" tabindex="38" v-on:keyup.enter="dispatch('windmere','rooms')" @click="dispatch('windmere','rooms')">Windmere Suite</div>
            <div class="textLink" role="button" tabindex="39" v-on:keyup.enter="dispatch('sunrise','rooms')" @click="dispatch('sunrise','rooms')">Sunrise Suite</div>
            <div class="textLink" role="button" tabindex="40" v-on:keyup.enter="dispatch('lilac','rooms')" @click="dispatch('lilac','rooms')">Lilac Room</div>
            <div class="textLink" role="button" tabindex="41" v-on:keyup.enter="dispatch('garden','rooms')" @click="dispatch('garden','rooms')">Garden Room</div>
            <div class="textLink" role="button" tabindex="42" v-on:keyup.enter="dispatch('lighthouse','rooms')" @click="dispatch('lighthouse','rooms')">Lighthouse Room</div>
        </template>
        <template v-slot:slot3>
            <div class="title4" role="heading">Contact Us</div>
            <div class="roomText1">Have any questions or need to book a room/retreat?</div>
            <div class="textLink" tabindex="44" @click="dispatch('contact')">Contact Us Today!</div>
        </template>
    </mainDiv2>
</template>

<script>
// import router from "../../../router.js";
import MainDiv2 from "../../MainDiv2.vue";
export default {
    components: {
        "mainDiv2": MainDiv2
    },
    data() {
        return {
            features: 'King bed\nPrivate bathroom with tub/shower\nWireless internet\nWingback chair\nElectric fireplace\nKitchenette with microwave, coffee pot and refrigerator\nTV (local channels) with DVD player',
            text2:'In the spring, pull back the shutters and open the window to savor fragrances from the nearby lilac bushes. In the dead of winter, close up the shutters to keep out the chill and enjoy the feeling of permanent springtime created by the delicate floral wallpaper and lilac ceiling. The Lilac Room is located on the second floor of the Inn and features a private bath, king bed and wing-back chair.',
            comfortsTitle:'Comforts For Your Stay',
            comforts:'Comfortable bed with quality cotton sheets in lovely rooms\nLuxurious robes\nPlush towels\nCeiling fans in most rooms\nHair dryers, iron/ironing board upon request\nComplimentary home-made snacks and beverages\nFull hot breakfast\nPersonal attention to meet your every need',
            spaTitle: 'Spa Services',
            spa: 'Spa services are also available by appointment for an additional charge. This is paid directly to the massage therapist by cash or check at the time of service. Please call at least 2 weeks ahead to arrange these. Click here for more information.',
            pleasuresTitle:'Pleasures For Your Stay',
            pleasures: '3 fireplaces in cozy locations\nExtensive library of books, and DVD’s\nDVD players\n1907 Steinway grand piano\nTable games, ping pong, table soccer',
            helpsTitle:'Helps For Your Stay',
            helps:'Exercise equipment (home gym, bike, treadmill)\nFree wireless internet\nAmple off-street parking',
            outdoorFunTitle:'Outdoor Fun For Your Stay',
            outdoorFun:'Private gazebo at back of property\nWrap-around porch with porch swing, chairs\nYard swing\nBonfires/s-mores',
            extrasTitle:'Extras For Your Stay',
            extras:'Chocolates and flowers can be waiting in the room when you arrive—for extra cost depending on the number and quality desired. This needs to be arranged at least 7 days ahead.',            
            selected: 'comforts',
            amenTitle: 'Comforts For Your Stay',
            amenData: 'Comfortable bed with quality cotton sheets in lovely rooms\nLuxurious robes\nPlush towels\nCeiling fans in most rooms\nHair dryers, iron/ironing board upon request\nComplimentary home-made snacks and beverages\nFull hot breakfast\nPersonal attention to meet your every need',
            images: {},
            imgIndex: 0,
            showAmenList: false,
        }
    },
    methods: {
        selectAmen(name){
            this.selected=name;
            if(name==='comforts'){
                this.amenTitle = this.comfortsTitle;
                this.amenData = this.comforts;
            } else if(name==='spa'){
                this.amenTitle=this.spaTitle;
                this.amenData=this.spa;
            } else if(name==='pleasures'){
                this.amenTitle=this.pleasuresTitle;
                this.amenData=this.pleasures;
            } else if(name==='helps'){
                this.amenTitle=this.helpsTitle;
                this.amenData=this.helps;
            } else if(name==='outdoorFun'){
                this.amenTitle=this.outdoorFunTitle;
                this.amenData=this.outdoorFun;
            } else if(name==='extras'){
                this.amenTitle=this.extrasTitle;
                this.amenData=this.extras;
            }
        },
        importAll(r) {
            var imgs = {}     
            console.log(r);
            r.keys().forEach(key=>(imgs[key]=r(key)));
            this.images = imgs;                         
        },
        getImagePath(){            
            if(Object.keys(this.images).length > 0){
                return require('../../../assets/2023Update/LilacRoom' +  Object.keys(this.images)[this.imgIndex].substring(1));
            }
            return"";
        },
        leftClick(){
            if(this.imgIndex === 0){
                this.imgIndex = Object.keys(this.images).length -1;
            } else {
                this.imgIndex = this.imgIndex - 1;
            }
        },
        rightClick(){
            if(this.imgIndex===(Object.keys(this.images).length-1)){
                this.imgIndex = 0;
            } else {
                this.imgIndex = this.imgIndex + 1;
            }
        },
        imgClick(btn){
            clearInterval(this.timer1);
            if(btn==='right'){
                this.rightClick();
                // this.showImg=false;
                // var timer3 = setInterval(() =>{
                //     this.rightClick();
                //     this.showImg=true;
                //     clearInterval(timer3);
                // },200)
            }else {
                this.leftClick();
            }            
            this.setTimer();
        },
        setTimer() {
            this.timer1=setInterval(() => {
                this.rightClick();
            },4000);
        },
        // dispatch(name, name2='rooms'){
        //     window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: name2 }}));
        //     router.replace({path: name});
        // },
    },    
    computed: {
        selTitle(){
            if(this.selected==='outdoorFun'){
                return 'Outdoor Fun';
            } else {
                return this.selected[0].toUpperCase() + this.selected.substring(1);
            }
        }
    },
    mounted() {
        this.importAll(require.context("../../../assets/2023Update/LilacRoom/", true, /\.jpg$/));        
        console.log(this.getImagePath());
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'rooms' }}));
    },
    created() {
        this.setTimer();
    }
}
</script>

<style scoped>
.title1 {
    font-size:35px;
    line-height:40px;
    color:#6d2328;
    font-family:Niconne; 
}
.title4 {    
    margin-bottom:15px;
}
.roomDesc {
    font-size:16px;
    margin: 20px 0 20px 0;
}
.roomImg {
    width:100%; 
    object-fit:cover;       
}
.textLink {
    font-size:16px;
    line-height:24px;
}
.roomLink:hover {
    cursor: pointer;
    text-decoration: underline;
    color:#476547;
}
.roomText1{
    margin-bottom:10px;
    font-weight:200;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.text2, .textPrice {
    margin-top:20px;
}
.textPrice {
    font-weight:300;
}
.textPriceSub{
    font-weight:100;
}
.space1{
    margin-top:30px;
}
.space2{
    margin-top:20px;
}
.blockTitle {
    color:#6d2328;
    font-family:Niconne; 
    font-size:28px;
    line-height:30px;
    margin-top:20px;
}
.block4 {
    display:flex;
    background-color:#fff;
    border: 1px solid #DDD;
    border-radius: 5px;    
    box-sizing:border-box;       
    /* height:100%; */
    height:320px;
}
.block5{
    display:flex;
    flex-flow: column;    
    align-items:stretch;    
}

.block5 > ul {    
    z-index:1;
    box-sizing:border-box;
    border-radius:5px 0 0 5px;    
    list-style-type: none;    
    height:fit-content;
    /* padding:20px 0 20px 0; */
    padding:0;
    margin:0;
    width:fit-content !important;
}
.block5 > ul > li {
    width:100%;
    height:35px;
    display:flex;
    align-items:center;
    padding:0 20px 0 20px;
    border-right:1px solid #ddd;        
    box-sizing:border-box;
    background: linear-gradient(to right,#FDFDFD 97%,#F0F0F0 100%);
}
.block5 > ul > li:hover {
    background-color:rgb(235, 235, 235);
    cursor:pointer;
}
.block5 .liSpace{        
    width:100%;      
    flex: 1 1 auto;
    border-right:1px solid #ddd;
    box-sizing: border-box;
    /* height:100%; */
    /* width:100% !important;
    border-right:1px solid #ddd;
    box-sizing:border-box; */
}
.block6 { 
    padding-left:4%;
    max-width:60%;
    font-size:16px;
    line-height:25px;    
    color:rgb(127, 127, 127);
}
.amenList > li {
    list-style-type: circle;
}
.styleSelected {
    border-top:1px solid #ddd;
    border-bottom:1px solid #ddd;
    border-right:none !important;
    box-sizing:border-box;
    background:none !important;    
}
.styleSelectedTop{
    border-bottom:1px solid #ddd;
    border-right:none !important;
    box-sizing:border-box;
    background:none !important;
    background-color:#fff;
}
.styleSelectedBottom {
    border-top:1px solid #ddd;    
    border-right:none !important;
    box-sizing:border-box;
}
</style>