<template>  
  <metainfo></metainfo>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "name": "Inn At Windmere",
        "image": "https://innatwindmere.com/img/logo.2e71598e.png",
        "telephone": "260.927.5116",
        "email": "inn@innatwindmere.com",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "2077 County Road 40",
          "addressLocality": "Auburn",
          "addressRegion": "IN",
          "addressCountry": "US",
          "postalCode": "46706"
        },
        "url": "https://innatwindmere.com/"
      }
    </component>
  </teleport>
  <div class="mainBackground">    
    <div v-if="isMobile && getSelected"  class="topSpace"></div>     
    <!-- <privacyPolicy class="PrivacyPolicy" v-click-outside="onClickOutside" v-if="showpp" /> -->
    <theHeader ref="header1" :is-mobile="isMobile"></theHeader>
    <router-view></router-view>
    <theFooter></theFooter>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
// import PrivacyPolicy from './components/pages/PrivacyPolicy.vue';
import {useMeta} from 'vue-meta';
export default {    
  setup() {
    useMeta({
      title: 'Inn At Windmere',
      htmlAttrs: { lang: 'en' },
      description: 'The Inn At Windmere is a beautiful bed and breakfast and retreat center. Full of country charm and beauty, it is located in Auburn, Indiana in the middle of forty sprawling acres of corn and bean fields.',
      keywords: 'b & b, bed and breakfast, bed and breakfast near me, bed and breakfast auburn indiana, lodging near fort wayne, lodging near trine university, lodging near auburn indiana',
      author: 'Andrew Rexroth, Hana Rexroth',
      viewport: 'width=device-width, initial-scale=1.0',
      robots: 'index, follow',
      link: [
        // { rel: 'canonical', href: 'https://innatwindmere.com/' },
        { rel: 'icon', type: 'image/png', href: '/logo.png' },
        { rel: 'apple-touch-icon', href: '/logo.png' }
      ],
      titleTemplate: '%s | Inn At Windmere',
    })
  },
  data() {
    return {
      isMobile:false,
      topHeight:null,
    }
  },
  components: {
    'theHeader': Header,
    'theFooter': Footer
    // 'privacyPolicy': PrivacyPolicy
  }, 
  computed: {
    getSelected() {
      if(this.$refs.header1.selected==='home'){
        return true;
      } else {
        return false;
      }
    },
    showpp() {
      return this.$store.getters.getShowPP
    }
  },
  methods: {    
    // backgroundClick(event){
    //   // console.log(event.clientX)
    //   // console.log(screen.width - event.clientX);
    //   if(this.isMobile && this.$refs.header1.showMenu){
    //     var isMenu1=false;
    //     // if(!this.isMobile){
    //     //   event.path.forEach((item) =>{
    //     //     if(item.className==='menu1'){
    //     //       isMenu1 = true;          
    //     //     }
    //     //   });
    //     // } else {              
    //       if(screen.width - event.clientX < 155){
    //         isMenu1=true;
    //       }
    //       // var el = event.srcElement.parentElement;          
    //       // while(el != null){
    //       //   if(el.className === 'menu1'){
    //       //     isMenu1=true;
    //       //   }
    //       //   el = el.srcElement;
    //       //   if(el != null){            
    //       //     el = el.parentElement;
    //       //   }
    //       // }
    //     // }
    //     if(isMenu1===false){
    //       // this.$refs.header1.closeMenu();
    //     }
    //   }
    // },
    // onClickOutside () {
    //   this.$store.dispatch('setShowPP', {showpp: false})
    // },
    middleware(e) {
      console.log(e);
      return true;
    }
  },
  mounted() {
    window.addEventListener('resize',this.windowSize);                
    this.windowSize();
    // this.$store.dispatch('setViewHeight', { viewHeight: window.innerHeight + 'px'} );

    const script = document.createElement('script');
    script.src = 'https://cdn.userway.org/widget.js';
    script.setAttribute('data-account', process.env.VUE_APP_USERWAY_DATA_ACCOUNT);
    script.setAttribute('alt', 'Accessibility Menu');
    document.head.appendChild(script);
  }
}
</script>

<style>
/* 
COLORS
#6d2328
#ffffcc
#575757

FONTS
H1: Snell Roundhand, cursive;
H2: Niconne
Nav: sans-serif
Info: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


*/
/* 8A084B 73242c match-dark:621f26 7c2832 match-light:7d323b 692534 match-2:6d2328*/
@import url('https://fonts.googleapis.com/css?family=Niconne');
html,
body,
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
  margin: 0px;
padding:0px;
-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box; 
  height:100%;
  width:100%;
  background-image: url("~@/assets/background.jpg");
  background-size: cover;
  /* min-height:100vh;
  min-width:100vw; */
}
.mainBackground {
  background:transparent;
  width:100%;
  height:100%;
  z-index:99;
}
.test {
  height:20px;
  width:20px;
  background-color:green;
  z-index:200;
}
.imgDiv1{
    width:100%;
    display:flex;    
    justify-content: center;
    align-items: center;
    position:relative;
    display:grid;
    grid-template-columns: 1fr;
}
.imgCenter{
    width:100%;
    z-index:1;    
    transition: all 1s ease;        
    grid-row-start:1;
    grid-column-start:1;
}
.leftBtn,.rightBtn {
    color:black;
    padding:0;    
    height:80px;   
    justify-content: center;
    align-items:center;
    display:flex;
    width:50px !important;
    opacity:.5;      
    border-radius: 4px;
    background-image: url("~@/assets/red2.jpg") !important;
    z-index:80;    
    position:absolute;    
    /* bottom:50; */
}
.leftBtn:hover,.rightBtn:hover{
    opacity:1;
    cursor:pointer;
}
.leftBtn{
    left:0;
}
.rightBtn {
    margin-left:-50px;
    right:0;
}
.v-enter-active {
    opacity:0;
    z-index:10;
}
.v-leave-active {
    opacity:1;    
    z-index:1;
}

.v-enter, .v-leave-to {
    opacity:0;
}
.v-leave, .v-enter-to {
    opacity:1;
}

.title1{
  font-size:35px;
    line-height:40px;
    /* color:#6d2328; */
    color:#6d2328;
    font-family:Niconne; 
}
.title2{
  color:#6d2328;
    font-family: Niconne;
    font-size: 28px;
    line-height: 30px;
    margin-top: 20px;
}
.title3 {
    color: rgb(87, 87, 87);
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.title4 {
    color: rgb(87, 87, 87);
    font-size:25px;
    line-height:40px;    
    font-family: Niconne;
}
.text1{
  color: rgb(87, 87, 87);
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
}
.text2{
  color: rgb(87, 87, 87);
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
}
.text3{
  font-size: 16px;
    line-height: 25px;
    color: rgb(127, 127, 127);
}

.textLink {    
  color:#6d2328;
    font-weight:200;
}
.textLink:hover{
    cursor: pointer;
    text-decoration: underline;
    color:#476547;
}
.block8 {
  display:none !important;
}
.block9 {
  display:none !important;
}
.space1 {
    height:30px;
}
.space2 {
  height:20px;
}
@media only screen and (max-width: 1152px) {
  
    .topSpace {
        height:55px;
        /* background-image: url("~@/assets/red2.jpg") !important; */
    }    
    .roomDesc {
      font-size:18px !important;
    }
    .block2 {
      font-size:16px !important;
    }
    .block5 {
      display:none !important;
    }
    .block6 {      
      width:90% !important;
      max-width:100% !important;
    }
    .block4 {
      display:block !important;
      height: auto !important;
    }
    .blockTitle {
      display: none !important
    }

    /* Rooms  */
    .amenDropDown {
    width:90%;
    margin-left:5%;
    margin-top:5%;
    padding:8px;
    box-sizing:border-box;
    /* border: 1px solid grey; */
    border-radius:5px;
    /* border:1px solid grey; */
    box-shadow: 0 0 3px rgb(89, 34, 34) ;
    text-align:center;
    font-size:22px;
    font-weight:300;
    z-index:10;
    position:relative;
}
.block7 {
    position:relative;
}
.block8 {
  display:block !important;
}
.block9 > ul {          
    margin:0;
    padding:0;
    list-style-type:none;
}
.block9 > ul > li {
    padding:5px;
    padding-left:10px;
}
.block9 {
  display:block !important;
    position:absolute;   
    width:88%;
    margin-left:6%;
    box-sizing:border-box;
    background-color:rgb(234, 234, 234);
    z-index:4;
}
.amenDropDown > img {
    height:22px;
    margin-left:5px;
    margin-bottom:-4px;
    box-sizing: border-box;
}
.rightBtn,.leftBtn {
  width:35px !important;
  height:50px;
}
}
  
</style>
