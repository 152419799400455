import {createStore} from 'vuex';
import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

const store= createStore({
    state() {
        return {
            routerHistory: [],
            selected: '',
            showpp: false,
            viewHeight: 0,
            
            // Amenities
            amenities: {
                comfortsTitle:'Comforts For Your Stay',
                comforts:'Comfortable bed with quality cotton sheets in lovely rooms\nLuxurious robes\nPlush towels\nCeiling fans in most rooms\nHair dryers, iron/ironing board upon request\nComplimentary home-made snacks and beverages\nFull hot breakfast\nPersonal attention to meet your every need',
                spaTitle: 'Spa Services',
                spa: 'Spa services are also available by appointment for an additional charge. This is paid directly to the massage therapist by cash or check at the time of service. Please call at least 2 weeks ahead to arrange these. Click here for more information.',
                spa1: 'Indulge yourself with spa services to enhance your relaxing time at the Inn At Windmere. These need to be scheduled at least 2 weeks in advance and must be paid for at the time of service with cash or check directly to the technician. Massages are usually available on site. Manicures and pedicures are available at a nearby spa 5 minutes away.',
                spa2: 'We have chosen spa providers who will truly pamper you. Their goal is to fulfill your specific desires and give you a satisfying, luxurious experience.',
                pleasuresTitle:'Pleasures For Your Stay',
                pleasures: '3 fireplaces in cozy locations\nExtensive library of books, and DVD’s\nDVD players\n1907 Steinway grand piano\nTable games, ping pong, table soccer',
                helpsTitle:'Helps For Your Stay',
                helps:'Exercise equipment (home gym, bike, treadmill)\nFree wireless internet\nAmple off-street parking',
                outdoorFunTitle:'Outdoor Fun For Your Stay',
                outdoorFun:'Private gazebo at back of property\nWrap-around porch with porch swing, chairs\nYard swing\nBonfires/s-mores',
                extrasTitle:'Extras For Your Stay',
                extras:'Chocolates and flowers can be waiting in the room when you arrive—for extra cost depending on the number and quality desired. This needs to be arranged at least 7 days ahead.',            
            }
        }
    },
    mutations,
    actions,
    getters
});

export default store;