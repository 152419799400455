import { createRouter, createWebHistory } from 'vue-router';
import Home from './components/pages/Home.vue';
import Rooms from './components/pages/Rooms.vue';
import Amenities from './components/pages/Amenities.vue';
import Retreats from './components/pages/Retreats.vue';
import Events from './components/pages/Events.vue';
import Attractions from './components/pages/Attractions.vue';
import Policies from './components/pages/Policies.vue';
import Contact from './components/pages/Contact.vue';
import Reservations from './components/pages/Reservations.vue';
import About from './components/pages/About.vue';
import Directions from './components/pages/Directions.vue';
import PrivacyPolicy from './components/pages/PrivacyPolicy.vue';

import WindmereSuite from './components/pages/rooms/WindmereSuite.vue';
import SunriseSuite from './components/pages/rooms/SunriseSuite.vue';
import Lilac from './components/pages/rooms/LilacRoom.vue';
import Garden from './components/pages/rooms/GardenRoom.vue';
import Lighthouse from './components/pages/rooms/LighthouseRoom.vue';

import ScrapbookingQuilting from './components/pages/retreats/ScrapbookingQuilting.vue';
import Church from './components/pages/retreats/Church.vue';

import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/',redirect: '/home'},
        { path: '/home', name: 'home', component: Home},
        { path: '/rooms', name: 'rooms',component: Rooms},
        { path: '/windmere', name: 'windmere', component: WindmereSuite},
        { path: '/sunrise', name: 'sunrise', component: SunriseSuite},
        { path: '/lilac', name: 'lilac', component: Lilac},
        { path: '/garden', name: 'garden', component: Garden},
        { path: '/lighthouse', name: 'lighthouse', component: Lighthouse},
        // { path: '/amenitiesDesc', name: 'amenitiesDesc', redirect: '/amenities', scrollBehavior: {x: 0, y: 100 }},
        { path: '/amenities', name: 'amenities',component: Amenities, },
        { path: '/retreats', name: 'retreats', component: Retreats},
        { path: '/scrapbooking', name: 'scrapbooking', component: ScrapbookingQuilting },
        { path: '/church', name: 'church', component: Church },
        { path: '/events', name: 'events', component: Events},
        { path: '/attractions', name: 'attractions', component: Attractions},
        { path: '/policies', name: 'policies', component: Policies},
        { path: '/contact', name: 'contact', component: Contact},
        { path: '/reservations', name: 'reservations', component: Reservations},
        { path: '/about', name: 'about', component: About },
        { path: '/directions', name: 'directions', component: Directions },
        { path: '/privacypolicy', name: 'privacyPolicy', component: PrivacyPolicy }
    ],
    scrollBehavior(from, to, savedPosition) {
      if(to.fullPath === '/'){
        const fromHistory = Boolean(savedPosition);        
        if (fromHistory && store.state.routerHistory.length > 0) {
          store.state.routerHistory.splice(-1, 1);
        } else {
          store.state.routerHistory.push(from);
        }
        
        return savedPosition || { x: 0, y: 0 };
      } else if(from.params['setScroll'] != null){
        return { x:0, y:100 }
      } else {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      }
    },        
    // scrollBehavior() {
    //   document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    // },
}
);
router.afterEach(to => {
  if(to.fullPath.includes('rooms') && to.fullPath != '/rooms'){
    localStorage.setItem('selected','rooms/' + to.name);
  }else if(to.fullPath.includes('retreats') && to.fullPath != '/retreats'){
    localStorage.setItem('selected','retreats/'+to.name);
  } else {
    localStorage.setItem('selected',to.name);
  }
  // console.log(to);
});

export default router;