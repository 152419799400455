<template>    
    <div class="header1">   
        <div v-if="isMobile" v-click-outside="onClickOutside" class="menu1">           
                <img alt="Three horizontal lines in a stack representing a menu." src="../assets/menuIcon1.png" role="button" @click="openMenu"/>
                <transition>
                    <div class="menu2" v-if="showMenu" >                        
                            <ul class="menuList1">
                                <li :class="[selected==='home'?'styleRed1':'']" role="button" @click="dispatch('home','home'); closeMenu()">Home</li>
                                <div class="listLine"></div>
                                <div class="subMenu3" v-if="showRoomsMenu"></div>
                                <div class="subMenu1" v-if="showRoomsMenu">
                                    <ul class="subMenu2">
                                        <li role="button" @click="dispatch('rooms','rooms'); closeMenu()">All</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('windmere','rooms'); closeMenu()">Windmere Suite</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('sunrise','rooms'); closeMenu()">Sunrise Suite</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('lilac','rooms'); closeMenu()">Lilac Room</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('garden','rooms'); closeMenu()">Garden Room</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('lighthouse','rooms'); closeMenu()">Lighthouse Room</li>
                                    </ul>
                                </div>
                                <li :class="[selected==='rooms'?'styleRed1':'']" role="button" @click="showRoomsMenu=!showRoomsMenu;showRetreatsMenu=false;showContactMenu=false">Rooms</li>
                                <div class="listLine"></div>                                
                                <div class="subMenu3" v-if="showRetreatsMenu"></div>
                                <div class="subMenu1" v-if="showRetreatsMenu">
                                    <ul class="subMenu2">
                                        <li role="button" @click="dispatch('retreats','retreats'); closeMenu()">All</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('scrapbooking','retreats'); closeMenu()">Scrapbooking / Quilting</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('church','retreats'); closeMenu()">Church</li>
                                    </ul>
                                </div>
                                <li :class="[selected==='retreats'?'styleRed1':'']" role="button" @click="showRetreatsMenu=!showRetreatsMenu; showRoomsMenu=false;showContactMenu=false">Retreats</li>                                
                                <div class="listLine"></div>
                                <li :class="[selected==='amenities'?'styleRed1':'']" role="button" @click="dispatch('amenities','amenities'); closeMenu()">Amenities</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='events'?'styleRed1':'']" role="button" @click="dispatch('events','events'); closeMenu()">Events</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='attractions'?'styleRed1':'']" role="button" @click="dispatch('attractions','attractions'); closeMenu()">Attractions</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='policies'?'styleRed1':'']" role="button" @click="dispatch('policies','policies'); closeMenu()">Policies</li>
                                <div class="listLine"></div>
                                <div class="subMenu3" v-if="showContactMenu"></div>
                                <div class="subMenu1" v-if="showContactMenu">
                                    <ul class="subMenu2">
                                        <li role="button" @click="dispatch('about','contact'); closeMenu()">About Us</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('contact','contact'); closeMenu()">Contact Us</li>
                                        <div class="listLine"></div>
                                        <li role="button" @click="dispatch('directions','contact'); closeMenu()">Directions</li>
                                    </ul>
                                </div>
                                <li :class="[selected==='contact'?'styleRed1':'']" role="button" @click="showContactMenu=!showContactMenu; showRoomsMenu=false;showRetreatsMenu=false">Contact</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='reservations'?'styleRed1':'']" role="button" @click="dispatch('reservations','reservations'); closeMenu()">Reservations</li>
                                <div class="listLine"></div>
                                <li :class="[selected==='privacyPolicy'?'styleRed1':'']" role="button" @click="dispatch('privacyPolicy','privacyPolicy'); closeMenu()">Privacy Policy</li>
                            </ul>
                        <!-- </div> -->
                    </div>                    
                </transition>
        </div>   
        <div class="header2" :style="styleHandler('header')" ref="header2">
            <div class="space1"></div>
            <div class="space3" v-if="isMobile"></div>
            <div class="top" role="banner" v-if="!isMobile">
                <div class="topLogo">
                    <img alt="Vintage looking gazebo." src="~@/assets/logo5.png">
                </div>
                <div class="topText" aria-label="Inn At Windmere heading.">
                    <div aria-label="Inn At Windmere" class="topText1">Inn At Windmere</div>
                    <div area-label="Bed & Breakfast Retreat Center" class="topText2">Bed & Breakfast ~ Retreat Center</div>
                </div>
            </div>
            <div class="top" v-if="isMobile">
                <h1 class="topText1" ><span>Inn At Windmere</span></h1>
                <div class="space4"></div>
                <div class="mLogo">
                    <div class="topText2">Bed & Breakfast</div>
                    <img alt="Vintage looking gazebo." src="~@/assets/logo.png" >
                    <div class="topText2">Retreat Center</div>
                </div>
            </div>
            <ul class="mainNav" v-if="!isMobile">
                <li role="button" tabindex="2" v-on:keydown.tab.exact="showRoomsDD=true" v-on:keyup.enter="showRoomsDD=false; navSelect('home')" @click="navSelect('home')" class="navItem" :class="{'styleRed': selected==='home'}">Home</li>
                <li>
                    <div class="navDropDown" tabindex="0" @mouseover="showRoomsDD=true" @mouseleave="showRoomsDD=false">
                        <div class="dNavItem" v-on:keydown.tab.shift="showRoomsDD=false" v-on:keyup.enter="showRoomsDD=false; navSelect('rooms')" tabindex="3" role="button" @click="navSelect('rooms')" :class="[selected==='rooms'?'styleRed':'']">Rooms</div>
                        <div class="dropDown" v-if="showRoomsDD">
                            <ul class="dropDownList">
                                <li role="button" tabindex="4" v-on:keyup.enter="showRoomsDD=false; dispatch('windmere', 'rooms')" @click="showRoomsDD=false; dispatch('windmere','rooms')">Windmere Suite</li>
                                <li role="button" tabindex="5" v-on:keyup.enter="showRoomsDD=false; dispatch('sunrise', 'rooms')" @click="showRoomsDD=false; dispatch('sunrise','rooms')">Sunrise Suite</li>
                                <li role="button" tabindex="6" v-on:keyup.enter="showRoomsDD=false; dispatch('lilac', 'rooms')" @click="showRoomsDD=false; dispatch('lilac','rooms')">Lilac Room</li>
                                <li role="button" tabindex="7" v-on:keyup.enter="showRoomsDD=false; dispatch('garden', 'rooms')" @click="showRoomsDD=false; dispatch('garden','rooms')">Garden Room</li>
                                <li role="button" v-on:keydown.tab.exact="showRoomsDD=false; showRetreatsDD=true" tabindex="8" v-on:keyup.enter="showRoomsDD=false; dispatch('lighthouse', 'rooms')" @click="showRoomsDD=false; dispatch('lighthouse','rooms')">Lighthouse Room</li>
                            </ul>
                        </div>
                    </div>
                </li>                
                <li>
                    <div class="navDropDown" tabindex="0" @mouseover="showRetreatsDD=true" @mouseleave="showRetreatsDD=false">
                        <div class="dNavItem" tabindex="9" v-on:keydown.tab.shift="showRetreatsDD=false; showRoomsDD=true" v-on:keyup.enter="showRetreatsDD=false; navSelect('retreats')" role="button" @click="navSelect('retreats')" :class="[selected==='retreats'?'styleRed':'']">Retreats</div>
                        <div class="dropDown" v-if="showRetreatsDD">
                            <ul class="dropDownList">
                                <li role="button" tabindex="10" @click="showRetreatsDD=false; dispatch('scrapbooking','retreats')">Scrapbooking / Quilting</li>
                                <li role="button" tabindex="11" v-on:keydown.tab.exact="showRetreatsDD=false" @click="showRetreatsDD=false; dispatch('church','retreats')">Church Retreats</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li role="button" tabindex="12" v-on:keydown.tab.shift="showRetreatsDD=true" v-on:keyup.enter="navSelect('amenities')" @click="navSelect('amenities')" class="navItem" :class="[selected==='amenities'?'styleRed':'']">Amenities</li>
                <li role="button" tabindex="13" v-on:keyup.enter="navSelect('events')" @click="navSelect('events')" class="navItem" :class="[selected==='events'?'styleRed':'']">Events</li>
                <li role="button" tabindex="14" v-on:keyup.enter="navSelect('attractions')" @click="navSelect('attractions')" class="navItem" :class="[selected==='attractions'?'styleRed':'']">Attractions</li>
                <li role="button" tabindex="15" v-on:keydown.tab.exact="showContactDD=true" v-on:keyup.enter="navSelect('policies')" @click="navSelect('policies')" class="navItem" :class="[selected==='policies'?'styleRed':'']">Policies</li>
                <li>
                    <div class="navDropDown" tabindex="0" @mouseover="showContactDD=true" @mouseleave="showContactDD=false">
                        <div class="dNavItem" v-on:keydown.tab.shift="showContactDD=false" v-on:keyup.enter="showContactDD=false; navSelect('contact')"  tabindex="16" role="button" @click="navSelect('contact')" :class="[selected==='contact'?'styleRed':'']">Contact</div>
                        <div class="dropDown" v-if="showContactDD">
                            <ul class="dropDownList">
                                <li role="button" tabindex="17" v-on:keyup.enter="showContactDD=false; dispatch('about', 'contact')" @click="showContactDD=false; dispatch('about','contact')">About Us</li>
                                <li role="button" tabindex="18" v-on:keyup.enter="showContactDD=false; dispatch('contact', 'contact')" @click="showContactDD=false; dispatch('contact','contact')">Contact Inn At Windmere, LLC</li>
                                <li role="button" tabindex="19" v-on:keydown.tab.exact="showContactDD=false" v-on:keyup.enter="showContactDD=false; dispatch('directions', 'contact')" @click="showContactDD=false; dispatch('directions','contact')">Directions</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li role="button" tabindex="20" v-on:keydown.tab.shift="showContactDD=true" v-on:keyup.enter="navSelect('reservations')" @click="navSelect('reservations')" class="navItem" :class="[selected==='reservations'?'styleRed':'']">Reservations</li>
                <li role="button" tabindex="21" v-on:keyup.enter="navSelect('privacyPolicy')" @click="navSelect('privacyPolicy')" class="navItem" :class="[selected==='privacyPolicy'?'styleRed':'']">Privacy Policy</li>
            </ul>
        </div>
    </div>
</template>

<script>
import router from '../router.js';
export default {
    props: ['isMobile'],
    data() {
        return {
            selected: this.$store.getters.getSelected != null ? ((this.$store.getters.getSelected).includes('rooms')? 'rooms' : (this.$store.getters.getSelected).includes('retreats'))? 'retreats' : this.$store.getters.getSelected : 'home',
            width: null,
            showRoomsDD:false,
            showRetreatsDD:false,
            showContactDD:false,
            // isMobile:false,
            topHeight: null,
            showMenu:false,
            showRetreatsMenu:false,
            showRoomsMenu: false,
            showContactMenu: false,
        }
    },
    computed: {
        curRouteName() {
            return this.$store.getters.getSelected;
        }
    },
    methods: {
        backgroundClick(){
            console.log('BackgroundClick');
        },
        onClickOutside() {
            this.closeMenu();
        },
        navSelect(tab){         
            this.selected=tab;        
            console.log('navSelect: ' + this.selected);     
            // this.curRouteName;
            router.replace({path: '/'+tab});       
        },
        handleResize() {
            this.width = screen.width * .6;
            this.topHeight = this.$refs.header2.clientHeight;
        },
        styleHandler(name) {
            if(name==="header"){                
                return {
                    "width":this.width+"px",                    
                    "margin":"auto",
                }  
            }
        },
        closeMenu(){                        
            this.showRetreatsMenu=false;
            this.showRoomsMenu=false;
            this.showContactMenu=false;
            this.showMenu=false;
        },
        openMenu() {
            this.showMenu=true;
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        
        window.addEventListener("updateNav", (event) => {
            this.selected = event.detail.selected;
        })        
        console.log('isMobile: ' + this.isMobile);
    },    
}
</script>

<style scoped>
h1 {
    all:unset;
}
.navDropDown, .dNavItem {
    position:relative;
    height:100%;
    display:flex;    
    align-items:center;
    width:100%;
    justify-content:center;    
}
.dropDown {
    position:absolute;
    display:block;
    top:100% !important;
    left:0 !important;
    right:0;
    background-image: url("~@/assets/nav.jpg");
    background-size:cover;   
    width:130%;
    display:flex;
    justify-content: center;
    z-index:100;
}
.dropDownList {
    padding:0;    
    width:100%;    
}
.dropDownList > li {
    padding:10px;
    box-sizing:border-box;
    width:100%;
    margin-left:calc()   
}
.dropDownList > li:hover {
    cursor:pointer;
    background-image: url("~@/assets/nav1.jpg");
    background-size:cover;
}
li {
    list-style-type:none;
}
.header1 {
    width:100%;
    /* display:flex;
    justify-content: center;             */    
}
.space1 {
    height:30px !important;
}
.mainNav {
    display:flex;
    justify-content: center;    
    height:3.5em;
    font-family:sans-serif;    
    font-weight:lighter;
    color:grey;
    padding:0;
    background-color:white;   
    margin:0; 
    width:100%;
    min-width:100%;
    position:relative;
    z-index:11;
}
.mainNav > li {
    width:12.5%;
    display:flex;    
    justify-content: center;
    align-items:center;    
    /* z-index:1;           */
}
.navItem, .dNavItem {
    background-image: url("~@/assets/nav.jpg");
    background-size:cover;
    border: .001px solid rgba(201, 191, 163, 0.716);    
    box-sizing: border-box;
}
.navItem:hover, .dNavItem:hover {
    cursor:pointer;
    background-image: url("~@/assets/nav.jpg");
    background-size:cover;
    background-color:white;
    opacity:.5 !important;
}
.dNavItem{
    /* z-index:10; */
}
.styleRed {
    background-image: url("~@/assets/red2.jpg") !important;
    border:none !important;    
    color:#ffffcc;
    box-shadow:0 .2px;
    box-sizing: border-box;
}
.styleRed:hover {
    opacity:1 !important;
}
.top {
    display:flex;    
}
.topLogo {
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.topLogo img {
    display:block;
    position:relative;
    width:230px;
    margin-bottom:-40px;
    z-index:1;
    /* max-width:90%; */
    /* width:110%; */    
}
.topText {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left:20px;
    margin-bottom:5px;
    /* background-color:green;         */
    
}
.topText1 {
    font-family:Snell Roundhand, cursive; 
    font-size:63px;
    line-height:80px;
    font-weight:700;
    color:#ffffcc;
}
.topText2 {
    font-family:Snell Roundhand, cursive; 
    font-size:2em;
    font-weight:500;
    color:#ffffcc;
}
.header2 {
    min-width:1152px;
}
@media only screen and (max-width: 1152px) {
    .header2 {
        margin:0 !important;
        width:100% !important;        
        min-width:100% !important;
    }
    /* .topText1 {
        height:160px;        
    } */
    .top {
        display:block;
        text-align:center;
    }

    .mLogo > img {
        width:75px;
        height:75px;
    }
    .mLogo { 
        width:100%;
        display:flex;
        justify-content: space-evenly;
    }
    .topText2 {
        font-size:19px;
        display:flex;
        align-items:center;
        justify-content: center;
        width:calc(calc(100% - 75px) / 2);
    }    
    .space3 {
        height:10px;
    }
    .space4 {
        height:5px;
    }

    .menu1 {
      position: absolute;
      top:20px;
      right:20px;
      display:block;
      z-index:100;
    }
    .menu1:hover {
        cursor: pointer;
    }
    .menu1 > img {
      width:40px;
      height:auto;
      background-color:#6d2328;      
      padding:4px;
      box-sizing: border-box;
      border-radius:4px;      
      box-shadow: 0 3px 6px rgba(68, 32, 32, 0.616), 0 0 5px rgb(89, 34, 34) inset;
      float:right;
    }
    .menu2 {
        position:relative;
        margin:0;
        /* padding:40px; */ 
        padding-top:85px;
        height:calc(100vh - 72px);
        margin-top:-20px;    
        /* right:-200px; */
        /* margin-right:-200px; */
        /* right:-20px; */
        transition: all .2s ease;
        margin-right:-20px;        
        background-color:rgb(110, 55, 55);    
        color:#ffffcc;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight:200;
        z-index:100;
    }
    .menuList1 {
        background-color:rgb(110, 55, 55);    
    }
    .menu2 > ul {
      margin:0;
      padding:0;
      list-style-type: none;
    }
    .menu2 >ul > li,.subMenu1 > ul > li {
      width:100%;
      height:40px;
      width:120px;
      padding-left:35px;
      display:flex;
      align-items:center;
    }    
    .menu2 >ul > li:hover,.subMenu1 > ul > li:hover{
        cursor: pointer;
        background-color:rgba(169, 161, 161, 0.657)
    }
    .subMenu1 > ul > li {
        padding-left:15px;
        padding-right:15px;
    }
    .subMenu1 > ul > li:active {
        background-color:#6d2328;
    }
    .subMenu3 {
        width:10px!important;
        height:40px;
        background-color:rgb(110, 55, 55);
    }
    .styleRed1 {
        background-color:#6d2328;        
    }
    .listLine {        
        /* width:80%;
        margin-left:10%; */
        width:100%;
        border-top:1px solid rgba(131, 131, 131, 0.532);
    }
    .subMenu3 {
        position:absolute;
        right:155px;
        background-color:rgb(110, 55, 55);  
    }
    .subMenu1 {
        position:absolute;
        right:162px;
        background-color:rgb(110, 55, 55);  
    }
    .subMenu1 > ul {
        padding: 0;
        margin: 0;
    }
    .v-enter-active {
        transform: translateX(200px);
    }
    .v-leave-active {
    }

    .v-enter, .v-leave-to {
        opacity:1;
        transform: translateX(200px);
    }
    .v-leave, .v-enter-to {
        /* margin-right:-20px; */
    }
}
</style>