<template>
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>    
    <mainDiv2 id="main">
        <template v-slot:slot1>
            <div class="title1" role="heading">Church Retreats</div>
            <div class="space1"></div>
            <div class="text2">
                <span class="span1">The Inn At Windmere, LLC would be thrilled to host church retreats. </span>
                <span class="span2">Whether a men or women’s conference, a couples’ retreat or a church staff meeting, you will find the Inn conducive to learning, reflection and growth as well as relaxation.</span>
                <div class="space2"></div>
                <div class="roomImg">
                    <div class="imgDiv1">
                        <div class="leftBtn" tabindex="30" v-on:keyup.enter="imgClick('left')" role="button"  @click="imgClick('left')">&lt;</div>
                        <transition>
                            <img alt="A carousel with 4 images: A large living room, A large table with 14 chairs around it, A fancy breakfast served on china, A nook with a chair and fireplace." class="imgCenter" :key="imgIndex" :src="getImagePath()" />
                        </transition>
                        <div class="rightBtn" tabindex="31" v-on:keyup.enter="imgClick('right')" role="button" @click="imgClick('right')">></div>
                    </div>            
                </div>
                <div class="space1"></div>
                <div class="title2" role="heading">Weekend packages for mens or womens groups</div>
                <ul>
                    <div class="featureList" v-for="str in features.split('\n')" :key="str">
                        <li>{{str}}</li>
                    </div>
                </ul>
                <div class="space1"></div>
                <div class="textItalic">For couples’ retreats, room rates are the same as what is listed on our <span role="button" @click="dispatch('rooms','rooms')" class="textLink">“Rooms”</span> Page.</div>
            </div>
        </template>
        <template v-slot:slot2>
            <div class="title4" role="heading">Retreats</div>
            <div class="textLink" role="button" tabindex="32" v-on:keyup.enter="dispatch('scrapbooking','retreats')" @click="dispatch('scrapbooking','retreats')">Scrapbooking / Quilting</div>
            <div class="textLink" role="button" tabindex="33" v-on:keyup.enter="dispatch('church','retreats')" @click="dispatch('church','retreats')">Church Retreats</div>
        </template>
        <template v-slot:slot3>
            <div class="title4" role="heading">Contact Us</div>
            <div class="roomText1">Have any questions or need to book a room/retreat?</div>
            <div class="textLink" role="button" tabindex="34" v-on:keyup.enter="dispatch('contact','contact')" @click="dispatch('contact', 'contact')">Contact Us Today!</div>
        </template>
    </mainDiv2>
</template>

<script>
import router from '../../../router.js';
import MainDiv2 from '../../MainDiv2.vue';
export default {    
    components: {
        'mainDiv2': MainDiv2
    },
    data() {
        return {
            images: {},
            imgIndex: 0,
            features: "Comfortable bed (twin or shared king/queen) with quality cotton sheets\nFull hot breakfast\nOther catered meals available for extra charge to be determined based on type of meal and number of people\nBeverages and snacks throughout your stay\nComfortable meeting room with grand piano, DVD player and screen, tables if desired\nFree Wi-Fi",
        }
    },
    methods:{
        importAll(r) {
            var imgs = {}     
            console.log(r);
            r.keys().forEach(key=>(imgs[key]=r(key)));
            this.images = imgs;                         
        },
        getImagePath(){            
            if(Object.keys(this.images).length > 0){
                return require('../../../assets/2023Update/Church' +  Object.keys(this.images)[this.imgIndex].substring(1));
            }
            return"";
        },
        leftClick(){
            if(this.imgIndex === 0){
                this.imgIndex = Object.keys(this.images).length -1;
            } else {
                this.imgIndex = this.imgIndex - 1;
            }
        },
        rightClick(){
            if(this.imgIndex===(Object.keys(this.images).length-1)){
                this.imgIndex = 0;
            } else {
                this.imgIndex = this.imgIndex + 1;
            }
        },
        imgClick(btn){
            clearInterval(this.timer1);
            if(btn==='right'){
                this.rightClick();
            }else {
                this.leftClick();
            }            
            this.setTimer();
        },
        setTimer() {
            this.timer1=setInterval(() => {
                this.rightClick();
            },4000);
        },
        dispatch(name, name2="retreats"){
            window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: name2 }}));
            router.replace({path: name});
        },
    },    
    mounted() {
        this.importAll(require.context("../../../assets/2023Update/Church/", true, /\.jpg$/));        
        console.log(this.getImagePath());
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'retreats' }}));
    },
    created() {
        this.setTimer();
    }
}
</script>
<style scoped>
.span1 {
    font-weight:300;
}
.space1 {
    height:30px;
}
.space2 {
    height:20px;
}
.textItalic {
    font-style:italic;
}
.featureList {
    font-size:17px;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.textLink {
    font-size:16px;
    line-height:24px;
}
.roomText1{
    margin-bottom:10px;
    font-weight:200;
}
</style>