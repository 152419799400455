export default {
    setShowPP (context, payload) {
        context.commit('setShowPP', payload);
    },
    setViewHeight(context, payload) {
        context.commit('setViewHeight', payload);
    },
    setLastPage(context, payload){
        context.commit('setLastPage', payload);
    },
    setSelected(context, payload) {
        context.commit('setSelected',payload);
    }
}