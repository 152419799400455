<template>  
  <a href="#main" tabindex="1" class="skip">Skip to main content</a>
  <MainDiv id="main">
  <div class="b1">
    <!-- <div class="b2" role="button" @click="this.$store.dispatch('setShowPP', { showpp: false })">X</div> -->
    <h1>Privacy Policy</h1>
    <p><span class="t1">Effective Date:</span> {{new Date().toLocaleString('default', {month: 'long', day: 'numeric', year: 'numeric'})}}</p>

    <p>At Inn At Windmere ("we", "our", "us"), your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your personal information when you visit our website <a href="https://innatwindmere.com">innatwindmere.com</a> and use the contact form to reach us.</p>

    <h2>1. Information We Collect</h2>
    <p>We may collect personal information through:</p>
    <ul>
        <li>Contact Form: First Name, Last Name, Email Address, Phone, and Your Message.</li>
        <li>Google Analytics: Information about your use of our website, such as pages visited, time spent, browser type, IP address, and device type.</li>
        <li>Cookies and Tracking Technologies: Our website may use cookies to improve your browsing experience and collect analytics data.</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>The information we collect is used to:</p>
    <ul>
        <li>Respond to inquiries and messages submitted through the contact form.</li>
        <li>Analyze website traffic and usage patterns to improve our website.</li>
        <li>Ensure the website functions properly and provide a better user experience.</li>
    </ul>

    <h2>3. Google Analytics and Cookies</h2>
    <p>We use Google Analytics to collect information about how visitors use our website. This helps us understand traffic patterns and improve user experience. Google Analytics uses cookies to track your interactions with our site. The data collected may include:</p>
    <ul>
        <li>Your IP address (anonymized where applicable).</li>
        <li>Pages visited and time spent on each page.</li>
        <li>Browser type, operating system, and device used.</li>
    </ul>

    <p>You can learn more about how Google collects and processes data <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">here</a>.</p>

    <h3>Managing Cookies</h3>
    <p>You can control or disable cookies through your browser settings. Note that disabling cookies may affect the functionality of the website.</p>

    <h2>4. How We Share Your Information</h2>
    <p>We do not sell, trade, or rent your personal information to third parties. However, we may disclose your information if required by law or to protect our legal rights.</p>

    <h2>5. Data Security</h2>
    <p>We take reasonable steps to protect your personal information, but no method of transmission over the internet is entirely secure. Please contact us if you have security concerns.</p>

    <h2>6. Your Rights</h2>
    <p>You have the right to:</p>
    <ul>
        <li>Access the personal data we hold about you.</li>
        <li>Request correction or deletion of your personal data.</li>
        <li>Opt-out of analytics tracking by disabling cookies in your browser or using tools like the <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.</li>
    </ul>

    <h2>7. External Links</h2>
    <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those sites.</p>

    <h2>8. Changes to This Privacy Policy</h2>
    <p>We may update this policy periodically. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy regularly.</p>

    <h2>9. Contact Us</h2>
    <p>If you have questions about this policy, contact us at:</p>
    <p>Email: <a href="mailto:inn@innatwindmere.com">inn@innatwindmere.com</a></p>

    <footer>
        <p style="width: 100%; text-align: center;">&copy; Inn At Windmere LLC {{new Date().getFullYear()}}. All rights reserved.</p>
    </footer>
  </div>
</MainDiv>
</template>
<script>
  import MainDiv from "../MainDiv.vue";
  export default {
    components: { MainDiv },
  //   computed: {
  //   viewHeight() {
  //     return this.$store.getters.viewHeight;
  //   }
  // },
  }
</script>
<style scoped>
h1 {
  width:100%;
  text-align: center;
}
h2{
    font-weight:250;
    font-size:24px;
    color:#4D4D4D;
    text-decoration: underline;
}

.t1 {
  font-weight: 400;
  color: #4D4D4D;
}  
.b1 {
  /* position:fixed;
  width:60vw;
  height:calc(v-bind('viewHeight') * .8);
  margin-top: calc(v-bind('viewHeight') * .1);
  margin-left: calc(calc(20vw - 2px) - 1%);
  padding: 1%;
  background-color: white;
  border: 2px solid rgb(100, 100, 100);
  box-sizing: border-box;
  overflow-y:scroll;
  z-index: 100; */
}
.b2 {  
  /* position: absolute;
  right:40px;
  top: 40px;
  border: 1px solid black;
  border-radius:5px;
  width:35px;
  height:35px;
  display:flex;
  justify-content: center;
  align-items:center;   */
}
.b2:hover {
  /* color: grey;
  border-color: grey;
  cursor: pointer; */
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
@media only screen and (max-width: 900px) {
.b1{
  /* width:80vw;
  margin-left: calc(calc(10vw - 2px) - 1%); */
}
}
</style>
