<template>
    <div class="mainDiv" :style="styleHandler('mainDiv')">
        <div class="block1">                        
            <div class="block2">
                <slot name="slot1"></slot>
            </div>            
            <div class="block3" v-if="!isMobile">
                <div class="block4"><slot name="slot2"></slot></div>
                <div class="block5"><slot name="slot3"></slot></div>
            </div>
        </div>        
    </div>
</template>

<script>
export default {    
    data() {
        return {
            width: null,
            isMobile: false,
        }
    },
    methods: {
        handleResize() {
            if(!this.isMobile){
                this.width = screen.width * .6;
            }else {
                this.width = 'auto';
            }
        },
        styleHandler(name) {
            if(name==="mainDiv"){
                // if(this.isMobile){
                    return {
                        "width":this.width+"px",
                        "margin":"auto",
                    } 
                // } else {            
                //     return {
                //         "width":this.width+"px",
                //         "min-width":"1152px",
                //         "margin":"auto",
                //     }
                // }
            }
        },
    },
    mounted() {
        window.addEventListener('resize',this.windowSize);            
        this.windowSize();   
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }
}
</script>
<style scoped>
.mainDiv {
    min-width:1152px;
}
.block1 {
    background-image: url("~@/assets/red2.jpg") !important;
    color:rgb(87, 87, 87);
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding:4%;
    display:flex;
    justify-content: space-between;
    white-space:break-spaces;
}


.block2{
    background-image: url("~@/assets/lightBackground2.jpg");    
    background-size:cover;
    color:rgb(87, 87, 87);
    font-weight:200;
    font-size:18px;
    line-height:22px;
    padding:20px;    
    width:calc(80% - calc(100% * (.04/.92)) - 40px);    
}
.block3 {
    width:20%;    
}
.block4, .block5 {
    background-image: url("~@/assets/lightBackground2.jpg");    
    background-size:cover;    
    padding:20px;
}
.block4 {
    margin-bottom:calc(100% * (.04/.92) + 40px);
}

@media only screen and (max-width: 1152px) {
    .mainDiv {
        /* margin:0 !important;
        min-width:100% !important; */
        min-width:unset !important;
    }
    .block1,.block2 {
        width:100% !important;
        box-sizing: border-box;
    }
}
</style>