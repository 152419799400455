export default {
    getShowPP: (state) => {
        return state.showpp;
    },    
    previousRoute: (state) => {
        const historyLen = state.routerHistory.length;
        if (historyLen == 0) return null;
        return state.routerHistory[historyLen - 1];
    },
    getSelected: () => {
        return localStorage.getItem('selected');
    },
    getAmen: (state) => (id) => {
        return state.amenities[id];
    },
    viewHeight: (state) => {
        return state.viewHeight;
    }
}