<template>
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>
    <mainDiv id="main">        
        <div class="title1" role="heading">Directions</div>
        <div class="space1"></div>
        <div class="text2">We are located in Auburn, at 2077 County Road 40, Auburn IN, 46706</div>
        <div class="space2"></div>
        <iframe aria-label="Reservations Webpage." src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5987.245966424352!2d-85.09601529142446!3d41.38227524308036!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88161a635d44d0dd%3A0x9103e25697e68dde!2sInn+At+Windmere!5e0!3m2!1sen!2sus!4v1556542653386!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen=""></iframe>
    </mainDiv>
</template>

<script>
import MainDiv from '../MainDiv.vue';
export default {    
    components: {
        'mainDiv': MainDiv
    },
    data() {
        return {
            images: {},
            imgIndex: 0,
            features: "2 nights on comfortable beds with luxurious cotton sheets and towels\nBeverages and delicious snacks throughout your stay\nAmple workspace—at least a 6 foot private table per person\nOtt light for each person\nWireless Internet\nDVD/VHS players\nSpa services for additional charge (click here for more information)\nAccess to comfortable sitting rooms with fireplaces to enjoy snacks, watch a movie or relax with friends",
            text1: "The Inn At Windmere was born as a result of the creative vision of Paul and Susie Rexroth, founders and owners. They have enjoyed the spacious country home since 1993 and found it a wonderful place to raise their eight children. Since turning it into a bed and breakfast, they have been excited to share their home with others.",
            text2: "When the Rexroth family moved onto the property, the home was just a square 100-year-old renovated farmhouse with a wing (now the Windmere Suite) on the side. Over the years they have added onto the home and enhanced the beauty of the property with the addition of the Great Room, several bedrooms, a beautiful gazebo and flower gardens. Paul and Susie’s dream to open a bed and breakfast on the lovely property came to fruition in the summer of 2007.",
            text3: "\"Windmere\" means \"Place of the Wind\". The owners of the Inn first visited the homestead on a crisp fall day in 1993. The country winds were at their best that day and led to the eventual naming of the home. Depending on the season of your visit, you will be able to experience this enjoyable facet of country life.",
        }
    },
    mounted() {                       
        window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'contact' }}));
    },
}
</script>
<style scoped>
.span1 {
    font-weight:300;
}
.space1 {
    height:30px;
}
.space2 {
    height:20px;
}
.textItalic {
    font-style:italic;
}
.featureList {
    font-size:17px;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.textLink {
    font-size:16px;
    line-height:24px;
}
.roomText1{
    margin-bottom:10px;
    font-weight:200;
}
.text1 {
    font-size:16px;    
}
.title4 {
    font-size:25px;        
    line-height:32px;
    /* color:#656565; */
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
}
.img1 {
    width:100%;
    margin-bottom:30px;
}
.boldText {
    font-weight:300;
}
.title4 {    
    margin-bottom:15px;
}
.textLink {
    font-size:16px;
    line-height:24px;
}
.roomText1{
    margin-bottom:10px;
    font-weight:200;
}
</style>