<template>
    <div class="mainDiv">
        <div class="rows">
            <div class="row1" v-if="!isMobile">
                <div class="row2">
                    <div class="rowTitle">Rooms</div>
                    <div class="rowItem" tabindex="40" role="button" @click="dispatch('windmere','rooms')">Windmere</div>
                    <div class="rowItem" tabindex="41" role="button" @click="dispatch('sunrise','rooms')">Sunrise</div>
                    <div class="rowItem" tabindex="42" role="button" @click="dispatch('lilac','rooms')">Lilac</div>
                    <div class="rowItem" tabindex="43" role="button" @click="dispatch('garden','rooms')">Garden</div>
                    <div class="rowItem" tabindex="44" role="button" @click="dispatch('lighthouse','rooms')">Lighthouse</div>
                </div>
            </div>
            <div class="row1">
                <div class="row2">
                    <div class="rowTitle">Contact Us</div>     
                    <div :style="[isMobile,{'text-decoration':'underline'}]" class="rowItem" role="button" tabindex="45" @click="dispatch('reservations','reservations')">Make a Reservation</div>                           
                    <div class="rowItem"><a aria-label="Opens a 'new message' in your default email app with the sender inn@innatwindmere.com" href="mailto:inn@innatwindmere.com">Email Us: inn@innatwindmere.com</a></div>
                    <div class="rowItem"><a target="_blank" aria-label="Opens Google Voice with the Inn At Windmere phone number in a new tab." href="tel:+12609275116">Call Us: 260.927.5116</a></div>                    
                </div>
            </div>
            <div class="row1" v-if="!isMobile">
                <div class="row2">
                    <div class="rowTitle">Our Memberships</div>
                    <!-- <div class="rowItem" role="button" @click="openLink('http://www.indianabedandbreakfast.org/Auburn-Indiana-bed-breakfast.html')">Indiana Bed & Breakfast Association</div> -->
                    <div class="rowItem" role="button" tabindex="46" @click="openLink('https://indianabedandbreakfast.org/listings/states/Indiana/auburn')">Indiana Bed & Breakfast Association</div>
                    <div class="rowItem" role="button" tabindex="47" @click="openLink('https://www.tripadvisor.com/Hotel_Review-g29323-d1206783-Reviews-Inn_At_Windmere-Auburn_Indiana.html')">Tripadvisor 2016 Certificate of Excellence Winner</div>
                    <!-- <div class="rowItem">Fort Wayne Newspapers 2013 Readers Choice 1st Place Winner</div> -->
                </div>
            </div>
        </div>
        <div class="copyRight">
            <div v-if="!isMobile" class="copyRight1">© Copyright Inn At Windmere LLC {{new Date().getFullYear()}} | Web Development by <span class="rd" role="button" @click="openLink('https://rexrothdev.com')">Rexroth Development</span></div>
            <div v-if="isMobile" class="copyRight1">© Copyright Inn At Windmere LLC {{new Date().getFullYear()}}<div>Web Development by <span class="rd" role="button" @click="openLink('https://rexrothdev.com')">Rexroth Development</span></div></div>
            <div class="copyRight2">
                <span v-if="!isMobile" class="rowItem" role="button" @click="dispatch('policies')">Policies</span>                
                <span v-if="!isMobile" class="rowItem" role="button" @click="dispatch('contact')">Contact</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isMobile:false,
        }
    },
    methods: {
    },
    mounted() {
        window.addEventListener('resize',this.windowSize);            
        this.windowSize();   
    }
}
</script>

<style scoped>
.mainDiv {
    width: 100%;
    background-image: url("~@/assets/nav.jpg");
    background-size:cover;    
}
.rows {
    display:flex;
    justify-content:space-around;
    color:#6d2328;
    margin-bottom:40px;
}
.row1 {
    width:30%;
    display:flex;
    justify-content:center;
}
.rowTitle {
    font-family:Niconne;     
    font-size:25px;
    font-weight:200px;
    margin-bottom:18px;
    margin-top:35px;
    text-align:center;
}
.rowItem {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size:16px;
    line-height:35px;
    font-weight:200;
    color:#6d2328;
}
.rowItem > a {
    text-decoration:none;
    color:#6d2328;
}
.rowItem:hover {
    cursor: pointer;
    text-decoration: underline;
    color:#476547;
}
.copyRight {
    width:100%;
    text-align:center;
    font-family:'Open Sans', 'Helvetica Neue', sans-serif;
    font-size:16px;
    line-height:35px;
    font-weight:100;
}
.rd {
    /* text-decoration: underline; */
    color:#6d2328;
    font-weight:200;
}
.rd:hover {
    cursor:pointer;
    text-decoration: underline;
    color:#476547;
}
.copyRight2 > span {
    margin-left:15px;
    margin-right:15px;
}
@media only screen and (max-width: 1152px) {
    .row1 {
        width:100%;
    }
}
</style>