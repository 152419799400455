<template>
    <a href="#main" tabindex="1" class="skip">Skip to main content</a>
    <mainDiv id="main">      
      <spin-modal :title="title" :loading="loading" v-if="showModal" @close="closeModal"></spin-modal>  
        <div class="title1" role="heading">Reservations</div>
                
        
        <iframe ref="iframe1" frameborder="0" src="https://via.eviivo.com/InnAtWindmere46706" class="iframe1" sandbox="allow-top-navigation allow-same-origin allow-scripts allow-popups allow-forms" :onbeforeunload="iframeRedirect">          
        </iframe>      
    </mainDiv>
</template>

<script>
// import iFrameResize from 'iframe-resizer/js/iframeResizer';
import MainDiv from '../MainDiv.vue';
import SpinModal from '../SpinModalEl.vue';
export default {    
  // directives: {
  //   'iframe-resize': {
  //     beforeMount(el, binding) {
  //       iFrameResize(binding.value, el)
  //     }
  //   },
  //   'resize': {
  //     bind: function(el, {value={}}) {
  //       el.addEventListener('load', () => iFrameResize(value, el))
  //     },
  //     unbind: function (el) {
  //       el.iFrameResizer.removeListeners();
  //     }
  //   }
  // },
  components: {
    'mainDiv': MainDiv,
    'spinModal':SpinModal,
  },
  data() {
    return {
      showModal: true,
        loading: true,        
        title: "loading...",
    }
  },
  methods: {
    closeModal(){
        this.showModal=false;
    },
    iframeRedirect(e){
      console.log(e);
      e.preventDefault();
      e.returnValue = 'Are You Sure?';
    }
  },
  mounted() {

    window.dispatchEvent(new CustomEvent('updateNav', { detail: { selected: 'reservations' }}));
    // edit the head of ref="iframe1" after the iframe is loaded
    // wait for ref="iframe1" to load
    this.$refs.iframe1.addEventListener('load', () => {
      // edit the head of ref="iframe1"
      this.$refs.iframe1.contentWindow.document.head.innerHTML = '<base href="https://via.eviivo.com/InnAtWindmere46706" target="_parent" />';
    });        
    // this.$refs.iframe1.contentWindow.document.head.innerHTML = '<base href="https://via.eviivo.com/InnAtWindmere46706" target="_parent" />';
  },
  created() {
    // window.addEventListener('beforeunload', this.iframeRedirect)
  }
}
</script>
<style scoped>
.row1 {
    display:flex;    
}
.img1 {
    margin-left:15px;
}
.skip {
  position: absolute;
  left: -10000px;
  top: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.skip:focus {
  left:0;
  width: auto;
  height: auto;
}
.space1{
    height:25px;
}
.space2{
    height:20px;
}
.iframe1 {
  position:relative;
  /* width:100% !important;
  max-width:100% !important; */
  width:100% !important;
  height:150vh;
  z-index:10;
  padding:0;
  margin:0;
}

</style>